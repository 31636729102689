.inputDate {
	padding: 10px;
	border-radius: 4px;
	border: 1px solid #999;
	max-width: 150px;

	&:last-child {
		margin: 0;
	}
}
