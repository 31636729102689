.ag-row {
    cursor: pointer;
}

.Grid-row-chiusa {
    background-color: rgba(255, 0, 0, 0.3) !important;
}

.Grid-row-sospesa {
    background-color: rgba(255, 255, 0, 0.3) !important;
}
