// Colors
$color-white: #FFF;
$color-black: #000;
$color-black--light: #27282D;
$color-grey: #556075;
$color-grey--light: #a5aabe;
$color-borders: #E5E5E5;
$color-blue: #0D6FEF;
$color-blue--light: #d1e4fe;
$color-green: #51940a;
$color-green--light: #19E263;
$color-red: #F96B6B;
$color-violet: #E2E7F0;
$color-violet--light: #C2CBDA;
$color-violet--dark: #949EB3;
$color-purple: #A65FD0;
$color-purple--light: #FF6496;
$color-pink: #EA5585;
$color-pink--light: #FFE9F0;
$color-beige: #FFE9DA;
$color-orange: #FF9854;
$color-orange--dark: #F27343;
