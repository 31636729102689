@import "../../shared/styles/colors";
@import "../../shared/styles/fonts";

.NavigationLink {
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	padding: 5px 10px;
	transition: all 300ms;
	flex-shrink: 0;
	@include font-main(1.125rem, $color-black--light, 600);
	border-bottom: 3px solid transparent;
	opacity: 0.5;

	&.is-active {
		cursor: default;
		border-color: $color-blue;
		opacity: 1;

		&:hover {
			opacity: 1;
			cursor: default;
		}
	}

	&:hover {
		opacity: 0.75;
	}
}
