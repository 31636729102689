@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700|Raleway:400,700');

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Raleway', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
