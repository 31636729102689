$font-main: 'Montserrat',
Helvetica,
Arial,
sans-serif;
$font-base: 'Raleway',
Helvetica,
Arial,
sans-serif;

@mixin font-base($size: false,
$colour: false,
$weight: false,
$lh: false,
$ls: false
) {
  font-family: $font-base;

  @if $size {
    font-size: $size;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh;
  }

  @if $ls {
    letter-spacing: $ls + px;
  }
}

@mixin font-main($size: false,
$colour: false,
$weight: false,
$lh: false,
$ls: false
) {
  font-family: $font-main;

  @if $size {
    font-size: $size;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh;
  }

  @if $ls {
    letter-spacing: $ls + px;
  }
}
